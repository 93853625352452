<template>
  <div>
    <b-row>
      <b-col>
        <b-button
          class="mb-1"
          variant="primary"
          @click="openModalFiltro"
        >
          <span class="align-middle">Novo Filtro</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <b-col
              cols="21"
              xl="6"
              class="d-flex justify-content-between flex-column"
            >
              <div class="align-items-center">
                <b-row>
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-warning"
                        rounded
                      >
                        <feather-icon
                          icon="TrendingUpIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ data.parcelas }}
                        </h5>
                        <small>Operações</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.honorario, true) }}
                        </h5>
                        <small>Total Hon. Variável</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.multa, true) }}
                        </h5>
                        <small>Total Multa</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.voz, true) }}
                        </h5>
                        <small>Remuneração</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-1">
                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.realizado, true) }}
                        </h5>
                        <small>Total Realizado</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.comissao, true) }}
                        </h5>
                        <small>Total Hon. Fixo</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-success"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.juros, true) }}
                        </h5>
                        <small>Total Juros</small>
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="d-flex align-items-center mr-2">
                      <b-avatar
                        variant="light-danger"
                        rounded
                      >
                        <feather-icon
                          icon="DollarSignIcon"
                          size="18"
                        />
                      </b-avatar>
                      <div class="ml-1">
                        <h5 class="mb-0">
                          {{ valorBr(data.repasse, true) }}
                        </h5>
                        <small>Total Repasse</small>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <b-row class="mt-2">
                  <b-col md="3">
                    <b-button
                      class="mb-1"
                      variant="outline-warning"
                      :disabled="!items.length > 0"
                      @click="enviarPlanilha"
                    >
                      <span class="align-middle">Enviar por Email</span>
                    </b-button>
                  </b-col>
                  <b-col>
                    <b-button
                      class="mb-1"
                      variant="outline-warning"
                      :disabled="!items.length > 0"
                      @click="exportXlsx"
                    >
                      <span class="align-middle">Exportar</span>
                    </b-button>
                  </b-col>
                </b-row>
              </div>
            </b-col>

            <b-col
              cols="21"
              xl="4"
            >
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="UserIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Credor</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      {{ filtro.credor ? filtro.credor.nome : '' }}
                    </b-badge>
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Vencimento Inicial</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_inicial }}
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Vencimento Final</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_final }}
                  </td>
                </tr>
                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Recebimento Inicio</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_recebimento_inicio }}
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CalendarIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Recebimento Final</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ filtro.data_recebimento_final }}
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="CreditCardIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Forma Pagamento</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      <div
                        v-for="name in filtro.forma_pagamento"
                        :key="name.formapagamento"
                      >
                        {{ filtro.forma_pagamento ? name.formapagamento: '' }}
                      </div>
                    </b-badge>
                  </td>
                </tr>

                <tr>
                  <th class="pb-50">
                    <feather-icon
                      icon="WatchIcon"
                      class="mr-75"
                    />
                    <span class="font-weight-bold">Tipo Pagamento</span>
                  </th>
                  <td class="pb-50">
                    <b-badge variant="primary">
                      <div
                        v-for="name in filtro.tipo_pagamento"
                        :key="name.tipopagamento"
                      >
                        {{ filtro.tipo_pagamento ? name.tipopagamento: '' }}
                      </div>
                    </b-badge>
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="mb-1">
            <b-col
              md="3"
            >
              <label for="">Filtro</label>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Digite para pesquisar"
              />
            </b-col>
          </b-row>
          <b-table
            hover
            striped
            responsive
            fixed
            :fields="fields"
            :items="items"
            :per-page="itemPerPage"
            :current-page="currentPage"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #table-colgroup="scope">
              <col
                v-for="field in scope.fields"
                :key="field.key"
                :style="{
                  width:
                    field.key === 'origem'
                      ? '400px'
                      : '180px',
                }"
              >
            </template>
            <template #cell(cliente)="row">
              {{ row.item.cliente ? firstAndSecondName(row.item.cliente) : '' }}
            </template>

            <template #cell(documento)="row">
              {{ row.item.documento ? cpfLabel(row.item.documento) : '' }}
            </template>

            <template #cell(vencimento)="row">
              {{ row.item.vencimento ? americaDate(row.item.vencimento) : '' }}
            </template>

            <template #cell(vencimento_original)="row">
              {{ row.item.vencimento_original ? americaDate(row.item.vencimento_original) : '' }}
            </template>

            <template #cell(recebimento)="row">
              {{ row.item.recebimento ? americaDate(row.item.recebimento) : '' }}
            </template>

            <template #cell(valor_nominal)="row">
              {{ row.item.valor_nominal ? `R$ ${parseFloat(row.item.valor_nominal).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_juros)="row">
              {{ row.item.valor_juros ? `R$ ${parseFloat(row.item.valor_juros).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_multa)="row">
              {{ row.item.valor_multa ? `R$ ${parseFloat(row.item.valor_multa).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_honorario)="row">
              {{ row.item.valor_honorario ? `R$ ${parseFloat(row.item.valor_honorario).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_comissao)="row">
              {{ row.item.valor_comissao ? `R$ ${parseFloat(row.item.valor_comissao).toFixed(2)}` : 'R$ 0,00' }}
            </template>

            <template #cell(valor_baixado)="row">
              {{ row.item.valor_baixado ? `R$ ${parseFloat(row.item.valor_baixado).toFixed(2)}` : 'R$ 0,00' }}
            </template>
            <template #cell(diasVenc)="row">
              {{ diasVencidos(row.item.vencimento_original, row.item.recebimento) }}
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="itemPerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-modal
      id="filtro"
      ref="filtro"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <b-row class="mb-1">
        <b-col md="12">
          <label for="">Selecione o credor</label>
          <v-select
            v-model="filtro.credor"
            :options="credores"
            label="nome"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col md="6">
          <label for="">Forma de Pagamento</label>
          <v-select
            v-model="filtro.forma_pagamento"
            :options="formaPagamento"
            multiple
            label="formapagamento"
          />
        </b-col>
        <b-col md="6">
          <label for="">Tipo de Pagamento</label>
          <v-select
            v-model="filtro.tipo_pagamento"
            :options="tipoPagamento"
            multiple
            label="tipopagamento"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="">Vencimento Início</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_inicial"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_inicial_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_inicial')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <label for="">Vencimento Final</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_final"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_final_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_final')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label for="">Recebimento Início</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_recebimento_inicio"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_recebimento_inicio_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_recebimento_inicio')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col>
          <label for="">Recebimento Final</label>
          <b-input-group class="mb-1">
            <the-mask
              v-model="filtro.data_recebimento_final"
              autocomplete="off"
              :mask="['##/##/####']"
              :masked="true"
              class="form-control"
            />
            <b-input-group-append>
              <b-form-datepicker
                v-model="filtro.data_recebimento_final_en"
                show-decade-nav
                button-only
                right
                size="sm"
                locale="en-US"
                aria-controls="example-input"
                @input="changeDataFormat($event, 'data_recebimento_final')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>
      <template #modal-footer="{cancel}">
        <div class="w-100">
          <b-button
            variant="outline-danger"
            class="float-right ml-1"
            @click="cancel()"
          >
            Cancelar
          </b-button>
          <b-button
            variant="warning"
            class="float-right"
            :disabled="validateForm"
            @click="getFilter(), load = true"
          >
            Filtrar
          </b-button>
        </div>
      </template>
      <b-overlay
        :show="load"
      />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
  BCard,
  BAvatar,
  BBadge,
  BTable,
  BPagination,
  BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { TheMask } from 'vue-the-mask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _ from 'lodash'
// eslint-disable-next-line no-unused-vars
import { saveAs } from 'file-saver'
import api from '@/../axios-auth'
// eslint-disable-next-line no-unused-vars
const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BModal,
    vSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormDatepicker,
    TheMask,
    BButton,
    BCard,
    BAvatar,
    BBadge,
    BTable,
    BPagination,
    BOverlay,
  },
  data() {
    return {
      credores: [],
      items: [],
      formaPagamento: [
        { formapagamento: 'Boleto', key: 'Boleto' },
        { formapagamento: 'Link Voz', key: 'link_voz' },
        { formapagamento: 'Link Credor', key: 'link_credor' },
        { formapagamento: 'Pix', key: 'pix' },
        { formapagamento: 'Maquineta', key: 'maquineta' },
      ],
      tipoPagamento: [
        { tipopagamento: 'Conta Voz', key: 'Conta Voz' },
        { tipopagamento: 'Conta Credor', key: 'Conta Credor' },
        { tipopagamento: 'Acordo Voz - pgt na instituição', key: null },
        { tipopagamento: 'Baixa administrativa', key: 'Baixa Administrativa' },
      ],
      filter: null,
      filterOn: [],
      itemPerPage: 20,
      currentPage: 1,
      totalRows: 1,
      data: {
        parcelas: 0,
        realizado: 0,
        voz: 0,
        honorario: 0,
        comissao: 0,
        repasse: 0,
        juros: 0,
        multa: 0,
      },
      filtro: {
        credor: null,
        forma_pagamento: null,
        tipo_pagamento: null,

        data_inicial: '',
        data_inicial_en: '',

        data_final: '',
        data_final_en: '',

        data_recebimento_inicio: '',
        data_recebimento_inicio_en: '',

        data_recebimento_final: '',
        data_recebimento_final_en: '',
      },
      filterInput: '',
      fields: [
        { key: 'cliente', label: 'cliente' },
        { key: 'documento', label: 'CPF/CNPJ' },
        { key: 'numero_acordo', label: 'Acordo' },
        { key: 'origem', label: 'origem' },
        { key: 'numero_operacao', label: 'operação' },
        { key: 'parcela', label: 'parcela' },
        { key: 'vencimento', label: 'vencimento' },
        { key: 'diasVenc', label: 'D. Venc', class: 'text-center' },
        { key: 'vencimento_original', label: 'Venc. Original' },
        { key: 'recebimento', label: 'recebimento' },
        { key: 'valor_nominal', label: 'Vl. Nominal' },
        { key: 'juros', label: 'juros' },
        { key: 'multa', label: 'multa' },
        { key: 'honorarios', label: 'Hon. Variável' },
        { key: 'comissao', label: 'Hon. Fixo' },
        { key: 'valor_baixado', label: 'valor baixado' },
      ],
      load: false,
    }
  },
  computed: {
    validateForm() {
      const { credor, data_inicial: dataInicial, data_final: dataFinal, data_recebimento_inicio: recebimentoInicio, data_recebimento_final: recebimentoFinal } = this.filtro
      const checkDataInicial = dataInicial ? dataInicial.length === 10 : null
      const checkDataFinal = dataFinal ? dataFinal.length === 10 : null
      const checkRecebimentoInicial = recebimentoInicio ? recebimentoInicio.length === 10 : null
      const checkRecebimentoFinal = recebimentoFinal ? recebimentoFinal.length === 10 : null
      if (credor && checkDataInicial && checkDataFinal) {
        return false
      }
      if (credor && checkRecebimentoInicial && checkRecebimentoFinal) {
        return false
      }
      return true
    },
  },
  watch: {
    'filtro.data_inicial': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_inicial_en !== newDate) {
            this.filtro.data_inicial_en = newDate
          }
        }
      },
      deep: true,
    },
    'filtro.data_final': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_final_en !== newDate) {
            this.filtro.data_final_en = newDate
          }
        }
      },
      deep: true,
    },
    'filtro.data_recebimento_inicio': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_recebimento_inicio_en !== newDate) {
            this.filtro.data_recebimento_inicio_en = newDate
          }
        }
      },
      deep: true,
    },
    'filtro.data_recebimento_final': {
      handler(n) {
        if (n.length === 10) {
          const splited = n.split('/')
          const newDate = `${splited[2]}-${splited[1]}-${splited[0]}`
          if (this.filtro.data_recebimento_final_en !== newDate) {
            this.filtro.data_recebimento_final_en = newDate
          }
        }
      },
      deep: true,
    },
  },
  created() {
    this.getCredores()
    this.dataAtualFiltro()
  },
  mounted() {
  },
  methods: {
    applyFilter() {
      const filterFirstName = this.filter.toLowerCase().trim().split(' ')[0]
      const filteredNames = this.items.filter(name => {
        const firstName = name.cliente.split(' ')[0]
        return firstName.toLowerCase().indexOf(filterFirstName) === 0
      })

      return filteredNames[0].cliente
    },
    diasVencidos(vencimentoOriginal, recebimento) {
      if (!recebimento) {
        return ''
      }
      const diaVencimento = Date.parse(vencimentoOriginal.substring(0, 10))
      const diaPagamento = Date.parse(recebimento.substring(0, 10))
      if (diaVencimento < diaPagamento) {
        const diferencaDeDias = diaPagamento - diaVencimento
        const diasVenc = diferencaDeDias / (1000 * 60 * 60 * 24)
        return diasVenc
      }

      return 0
    },
    async getCredores() {
      const credores = await api.get('api/v1/credores/').then(result => {
        const { dados } = result.data
        return dados
      }).catch(() => [])
      credores.sort((a, b) => a.nome.localeCompare(b.nome)) // ordena os credores pelo nome em ordem alfabética
      this.credores = credores
      return credores
    },
    async openModalFiltro() {
      this.credores

      this.filtro.data_inicial = ''
      this.filtro.data_inicial_en = ''
      this.filtro.data_final = ''
      this.filtro.data_final_en = ''
      this.filtro.data_recebimento_inicio = ''
      this.filtro.data_recebimento_inicio_en = ''
      this.filtro.data_recebimento_final = ''
      this.filtro.data_recebimento_final_en = ''
      this.filtro.tipo_pagamento = null

      this.$refs.filtro.show()
    },

    changeDataFormat(item, pos) {
      const splited = item.split('-')
      const newDate = `${splited[2]}/${splited[1]}/${splited[0]}`
      if (!this.filtro[pos] !== newDate) {
        this.filtro[pos] = newDate
      }
    },
    dataAtualFiltro() {
      const hoje = new Date()
      const dia = hoje.getDate().toString().padStart(2, '0')
      const mes = String(hoje.getMonth() + 1).padStart(2, '0')
      const ano = hoje.getFullYear()
      const dataAtual = `${dia}/${mes}/${ano}`
      this.filtro.data_recebimento_inicio_en = dataAtual
      this.filtro.data_recebimento_inicio = dataAtual
      this.filtro.data_recebimento_final = dataAtual
      this.filtro.data_recebimento_final_en = dataAtual
    },

    getFilter() {
      const {
        credor,
        // eslint-disable-next-line camelcase
        forma_pagamento: forma_pg,
        // eslint-disable-next-line camelcase
        data_inicial_en: data_inicio,
        // eslint-disable-next-line camelcase
        data_final_en: data_final,
        // eslint-disable-next-line camelcase
        data_recebimento_inicio_en: data_recebimento_inicio,
        // eslint-disable-next-line camelcase
        data_recebimento_final_en: data_recebimento_final,
        // eslint-disable-next-line camelcase
        tipo_pagamento: tipo_pg,
      } = this.filtro

      const credorID = [credor.id]
      // eslint-disable-next-line camelcase
      const formaP = forma_pg ? forma_pg.map(item => item.key) : []
      // eslint-disable-next-line camelcase
      const tipoP = tipo_pg ? tipo_pg.map(item => item.key) : []

      const body = {
        credor_id: credorID,
        ...(data_inicio.length === 0 && data_final.length === 0
          ? {
            data_recebimento_inicio,
            data_recebimento_final,
          }
          : {
            data_inicio,
            data_final,
          }),
      }
      if (formaP.length) {
        body.forma_pagamento = formaP
      }
      if (tipoP.length) {
        body.tipo_pagamento = tipoP
      }
      api.post('api/v1/repasses/filtrar', body, {
        headers: {
          Accept: 'application/json',
        },
      }).then(async result => {
        this.load = false
        const sortToArray = result.data.dados
        const newArray = _.orderBy(sortToArray, ['cliente', 'aluno', 'numero_operacao', 'parcela'], ['asc'])
        this.items = newArray
        this.totalRows = result.data.dados.length
        this.setData()
        this.$refs.filtro.hide()
      })
    },

    setData() {
      const { items } = this
      const data = {
        parcelas: 0,
        realizado: 0,
        voz: 0,
        honorario: 0,
        comissao: 0,
        repasse: 0,
        juros: 0,
        multa: 0,
      }
      if (items) {
        data.parcelas = items.length
        items.map(item => {
          !item.valor_comissao ? item.valor_comissao = 0 : null
          !item.valor_honorario ? item.valor_honorario = 0 : null

          const voz = /* parseFloat(parseFloat(item.valor_comissao).toFixed(2)) */0 + parseFloat(parseFloat(item.valor_honorario).toFixed(2))
          data.realizado += parseFloat(parseFloat(item.realizado).toFixed(2))
          data.honorario += parseFloat(parseFloat(item.honorarios).toFixed(2))
          // data.comissao += parseFloat(parseFloat(item.valor_comissao).toFixed(2))
          data.repasse += (parseFloat(parseFloat(item.valor_repasse).toFixed(2)) - parseFloat(parseFloat(item.honorarios).toFixed(2)))
          data.voz += voz
          data.juros += parseFloat(parseFloat(item.juros).toFixed(2))
          data.multa += parseFloat(parseFloat(item.multa).toFixed(2))
          data.comissao += parseFloat(parseFloat(item.comissao).toFixed(2))
        })
      }
      data.realizado = parseFloat(data.realizado.toFixed(2))
      data.voz = parseFloat(data.voz.toFixed(2))
      data.repasse = parseFloat(data.repasse.toFixed(2))
      data.comissao = parseFloat(data.comissao.toFixed(2))
      this.data = data
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async enviarPlanilha() {
      const { blob: file, table } = await this.getXlsx()
      if (file) {
        const { credor, data_recebimento_final } = this.filtro
        const emailBody = new FormData()
        // const cc = [
        //   { name: 'rafhael', address: 'rafhaelgigalucas@gmail.com' },
        //   { name: 'Jairo', address: 'jairorodrigues.sousa@gmail.com ' },
        // ]

        const cc = [
          { name: 'Marcos', address: 'marcos@vozeduca.com' },
          { name: 'Joao', address: 'joao.santos@vozeduca.com' },
          { name: 'Henrique', address: 'henrique@vozeduca.com.br' },
        ]

        const response = await api.get(`api/v1/credor_contatos/${credor.id}`)
        const { dados } = response.data
        const to = dados
          .filter(item => item.relatorios.some(relat => relat.title === 'Prestação de contas'))
          .map(item => ({
            name: item.nome,
            address: item.email,
          }))

        emailBody.append('cc', JSON.stringify(cc))
        emailBody.append('to', JSON.stringify(to))
        // eslint-disable-next-line camelcase
        emailBody.append('subject', `${credor.nome}, aqui estão seus recebimentos do dia ${data_recebimento_final}`)
        emailBody.append('date', data_recebimento_final)
        emailBody.append('planilha', file)
        emailBody.append('credor', credor.nome)
        emailBody.append('table', table)

        api.post(`${process.env.VUE_APP_ROOT_HUBLE}/repasse-planilha`, emailBody).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Notificação',
              icon: 'BellIcon',
              text: '👋 Email Enviado',
              variant: 'success',
            },
          }, { position: 'top-center' })
        })
      }
    },
    async exportXlsx() {
      // eslint-disable-next-line no-unused-vars
      const { blob: file } = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    // eslint-disable-next-line consistent-return
    async getXlsx() {
      const { credor, data_recebimento_final } = this.filtro
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'VOZ - COBRANÇA'
      workbook.created = new Date()

      const worksheet2 = workbook.addWorksheet('Operações')
      const worksheet3 = workbook.addWorksheet('Acordos')
      const worksheet = workbook.addWorksheet('Parcelas')
      let parcelas
      if (this.filter) {
        const nameReturned = this.applyFilter()
        parcelas = this.items.filter(item => item.cliente === nameReturned).map(item => item.parcela_id)
      } else {
        parcelas = this.items.map(item => item.parcela_id)
      }
      parcelas = [...new Set(parcelas)]
      worksheet.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Situação', key: 'situacao' },
        { header: 'Acordo' },
        { header: 'Vencimento' },
        { header: 'Pagamento' },
        { header: 'Parcela' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]
      worksheet2.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'Aluno' },
        { header: 'CPF/CNPJ' },
        { header: 'Situação', key: 'situacao' },
        { header: 'Acordo' },
        { header: 'Nº Operação' },
        { header: 'Origem' },
        { header: 'Vencimento Ano' },
        { header: 'Dias Vencidos', key: 'diasVenc' },
        { header: 'Vencimento Original' },
        { header: 'Pagamento' },
        { header: 'Parcela' },
        { header: 'Principal', key: 'principal' },
        { header: 'Juros', key: 'juros' },
        { header: 'Multa', key: 'multa' },
        { header: 'Desconto', key: 'desconto' },
        { header: 'Hon. Variável', key: 'honorarios' },
        { header: 'Realizado', key: 'realizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Repasse', key: 'repasse' },
        { header: 'Forma' },
      ]
      worksheet3.columns = [
        { header: 'Instituição de Ensino' },
        { header: 'Cliente' },
        { header: 'CPF/CNPJ' },
        { header: 'Acordo' },
        { header: 'Condição' },
        { header: 'Qtd. Parcelas' },
        { header: 'Qtd. Operações' },
        { header: 'Principal', key: 'valor_acordo_total_principal' },
        { header: 'Juros', key: 'valor_acordo_total_juros' },
        { header: 'Multa', key: 'valor_acordo_total_multas' },
        { header: 'Desconto', key: 'valor_acordo_total_desconto' },
        { header: 'Hon. Variável', key: 'valor_acordo_total_honorarios' },
        { header: 'Realizado', key: 'valor_acordo_total_realizado' },
        { header: 'Taxa Cartão', key: 'taxa_cartao' },
        { header: 'Hon. Fixo', key: 'comissao' },
        { header: 'Detalhamento', key: 'detalhamento' },
      ]

      const wk1Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        'taxa_cartao',
        'comissao',
        'repasse',
      ]

      const wk2Columns = [
        'principal',
        'juros',
        'multa',
        'desconto',
        'honorarios',
        'realizado',
        'taxa_cartao',
        'comissao',
        'repasse',
      ]

      const wk3Columns = [
        'valor_acordo_total_principal',
        'valor_acordo_total_juros',
        'valor_acordo_total_multas',
        'valor_acordo_total_desconto',
        'valor_acordo_total_honorarios',
        'valor_acordo_total_realizado',
        'taxa_cartao',
        'comissao',
      ]

      const totais = {
        wk1: {},
        wk2: {},
        wk3: {},
        txCartao: 0,
      }

      let totaisCPF = []

      const acordosDone = []
      const dadosOperacao = []
      // soma da comissao e parcela de cartao de todas os items para a operaçao
      const somaAcordos = {}
      const somaCart = {}
      this.items.forEach(item => {
        const nAcordo = item.numero_acordo
        const valorAcordo = parseFloat(item.comissao)
        const valorCart = parseFloat(item.taxa_cartao)

        if (!Object.prototype.hasOwnProperty.call(somaAcordos, nAcordo)) {
          somaAcordos[nAcordo] = 0
        }
        if (!Object.prototype.hasOwnProperty.call(somaCart, nAcordo)) {
          somaCart[nAcordo] = 0
        }

        somaCart[nAcordo] += valorCart
        somaAcordos[nAcordo] += valorAcordo
      })
      Object.keys(somaAcordos).forEach(nAcordo => {
        somaAcordos[nAcordo] = parseFloat(somaAcordos[nAcordo].toFixed(2))
      })
      Object.keys(somaCart).forEach(nAcordo => {
        somaCart[nAcordo] = parseFloat(somaCart[nAcordo].toFixed(2))
      })
      parcelas.map(p => {
        const filtered = this.items.filter(e => e.parcela_id === p)
        const ids = filtered.map(po => {
          totais.txCartao += po.taxa_cartao
          totaisCPF.push(po.documento)
          dadosOperacao.push({
            instituicao: po.instituicao,
            cliente: po.cliente,
            aluno: po.aluno,
            documento: po.documento ? this.cpfLabel(po.documento) : '',
            situacao: po.situacao,
            numero_acordo: po.numero_acordo,
            numero_operacao: po.numero_operacao,
            origem: po.origem,
            vencimento_original: po.vencimento_original.split('-')[0],
            diasVenc: this.diasVencidos(po.vencimento_original, po.recebimento),
            vencimento: this.americaDate(po.vencimento_original),
            recebimento: po.recebimento ? this.americaDate(po.recebimento) : '',
            parcela: po.parcela,
            principal: parseFloat(po.principal),
            juros: parseFloat(po.juros),
            multa: parseFloat(po.multa),
            desconto: parseFloat(po.desconto),
            honorarios: parseFloat(po.honorarios),
            realizado: parseFloat(po.realizado),
            taxa_cartao: parseFloat(po.taxa_cartao),
            comissao: parseFloat(po.comissao),
            repasse: parseFloat(po.repasse),
            forma: po.forma,
          })
          // worksheet2.addRow([
          //   po.instituicao,
          //   po.cliente,
          //   po.aluno,
          //   po.documento ? this.cpfLabel(po.documento) : '',
          //   po.situacao,
          //   po.numero_acordo,
          //   po.numero_operacao,
          //   po.origem,
          //   po.vencimento_original.split('-')[0],
          //   this.americaDate(po.vencimento_original),
          //   po.recebimento ? this.americaDate(po.recebimento) : '',
          //   po.parcela,
          //   parseFloat(po.principal),
          //   parseFloat(po.juros),
          //   parseFloat(po.multa),
          //   parseFloat(po.desconto),
          //   parseFloat(po.honorarios),
          //   parseFloat(po.realizado),
          //   parseFloat(po.taxa_cartao),
          //   parseFloat(po.comissao),
          //   parseFloat(po.repasse),
          //   po.forma,
          // ])
          // totais.wk2[] = {}
          wk2Columns.map(w => {
            totais.wk2[w] ? totais.wk2[w] += parseFloat(po[w]) : totais.wk2[w] = parseFloat(po[w])
          })
          if (!acordosDone.includes(po.numero_acordo)) {
            acordosDone.push(po.numero_acordo)

            const qntOp = parseInt(po.parcela.split('/')[1])
            const acordoProp = {
              valor_acordo_total_principal: 0,
              valor_acordo_total_juros: 0,
              valor_acordo_total_multas: 0,
              valor_acordo_total_desconto: 0,
              valor_acordo_total_honorarios: 0,
              valor_acordo_total_realizado: 0,
              realizado: 0,
              taxa_cartao: 0,
              comissao: 0,
            }
            const acordosPo = filtered.filter(e => e.numero_acordo === po.numero_acordo)
            acordosPo.map(i => {
              Object.keys(acordoProp).map(key => {
                if (acordosPo.length > 1) {
                  acordoProp[key] = parseFloat(i[key])
                } else {
                  acordoProp[key] = parseFloat(po[key])
                }
              })
              // atribuicao do valor total da comisao e cartao para o acordoProp para o seu respectivo acordo
              if (Object.prototype.hasOwnProperty.call(somaCart, i.numero_acordo)) {
                acordoProp.taxa_cartao = somaCart[i.numero_acordo]
              }
              if (Object.prototype.hasOwnProperty.call(somaAcordos, i.numero_acordo)) {
                acordoProp.comissao = somaAcordos[i.numero_acordo]
              }
            })
            worksheet3.addRow([
              po.instituicao,
              po.cliente,
              po.documento ? this.cpfLabel(po.documento) : '',
              po.numero_acordo,
              parseInt(po.parcela.split('/')[1]) > 1 ? 'PARCELADO' : 'AVISTA',
              qntOp,
              filtered.length,
              parseFloat(acordoProp.valor_acordo_total_principal),
              parseFloat(acordoProp.valor_acordo_total_juros),
              parseFloat(acordoProp.valor_acordo_total_multas),
              parseFloat(acordoProp.valor_acordo_total_desconto),
              parseFloat(acordoProp.valor_acordo_total_honorarios),
              parseFloat(acordoProp.valor_acordo_total_realizado),
              parseFloat(acordoProp.taxa_cartao),
              parseFloat(acordoProp.comissao),
              `1ª parcela no valor de R$ ${this.valorBr(parseFloat(acordoProp.realizado))}`,
            ])
            wk3Columns.map(w => {
              totais.wk3[w] ? totais.wk3[w] += parseFloat(acordoProp[w]) : totais.wk3[w] = parseFloat(acordoProp[w])
            })
          }
          return po
        })
        const parc = {
          instituicao: '',
          cliente: '',
          documento: '',
          situacao: '',
          numero_acordo: '',
          vencimento: '',
          recebimento: '',
          parcela: '',
          principal: 0,
          juros: 0,
          multa: 0,
          desconto: 0,
          honorarios: 0,
          realizado: 0,
          taxa_cartao: 0,
          comissao: 0,
          repasse: 0,
          forma: '',
        }
        ids.map(i => {
          parc.instituicao = i.instituicao
          parc.cliente = i.cliente
          parc.documento = this.cpfLabel(i.documento) || ''
          parc.situacao = i.situacao
          parc.numero_acordo = i.numero_acordo
          parc.vencimento = this.americaDate(i.vencimento) || ''
          parc.recebimento = i.recebimento ? this.americaDate(i.recebimento) : '' || ''
          parc.parcela = i.parcela
          parc.principal += parseFloat(i.principal)
          parc.juros += parseFloat(i.juros)
          parc.multa += parseFloat(i.multa)
          parc.desconto += parseFloat(i.desconto)
          parc.honorarios += parseFloat(i.honorarios)
          parc.realizado += parseFloat(i.realizado)
          parc.taxa_cartao += parseFloat(i.taxa_cartao)
          parc.comissao += parseFloat(i.comissao)
          parc.repasse += parseFloat(i.repasse)
          parc.forma = i.forma

          wk1Columns.map(w => {
            totais.wk1[w] ? totais.wk1[w] += parseFloat(i[w]) : totais.wk1[w] = parseFloat(i[w])
          })
        })
        worksheet.addRow([
          parc.instituicao,
          parc.cliente,
          parc.documento ? this.cpfLabel(parc.documento) : '',
          parc.situacao,
          parc.numero_acordo,
          parc.vencimento ? this.americaDate(parc.vencimento) : '',
          parc.recebimento ? this.americaDate(parc.recebimento) : '',
          parc.parcela,
          parseFloat(parc.principal.toFixed(2)),
          parseFloat(parc.juros.toFixed(2)),
          parseFloat(parc.multa.toFixed(2)),
          parseFloat(parc.desconto.toFixed(2)),
          parseFloat(parc.honorarios.toFixed(2)),
          parseFloat(parc.realizado.toFixed(2)),
          parseFloat(parc.taxa_cartao),
          parseFloat(parc.comissao.toFixed(2)),
          parseFloat(parc.repasse.toFixed(2)),
          parc.forma,
        ])
      })

      totaisCPF = [...new Set(totaisCPF)]
      const credoresIma = [126, 76, 140, 105, 112, 115, 139, 161]
      if (credoresIma.includes(this.filtro.credor.id) && this.filtro.forma_pagamento
          && (this.filtro.forma_pagamento[0].key === 'link_credor'
              || this.filtro.forma_pagamento[0].key === 'maquineta')) {
        const newDadosOperacao1 = _.orderBy(dadosOperacao, ['cliente', 'aluno', 'numero_operacao', 'parcela'], ['asc'])

        const operacoesAgrupadas = _.groupBy(newDadosOperacao1, 'numero_operacao')

        const operacoesAgrupadasList = Object.values(operacoesAgrupadas)

        const worksheet2Data = operacoesAgrupadasList.map(operacoes => {
          const firstOperacao = operacoes[0]
          const principalTotal = _.sumBy(operacoes, 'principal')
          const jurosTotal = _.sumBy(operacoes, 'juros')
          const multaTotal = _.sumBy(operacoes, 'multa')
          const descontoTotal = _.sumBy(operacoes, 'desconto')
          const honorariosTotal = _.sumBy(operacoes, 'honorarios')
          const realizadoTotal = _.sumBy(operacoes, 'realizado')
          const taxaCartaoTotal = _.sumBy(operacoes, 'taxa_cartao')
          const comissaoTotal = _.sumBy(operacoes, 'comissao')
          const repasseTotal = _.sumBy(operacoes, 'repasse')
          const parcelaTotal = _.maxBy(operacoes, 'parcela').parcela.split('/')[1]
          return [
            firstOperacao.instituicao,
            firstOperacao.cliente,
            firstOperacao.aluno,
            firstOperacao.documento ? this.cpfLabel(firstOperacao.documento) : '',
            firstOperacao.situacao,
            firstOperacao.numero_acordo,
            firstOperacao.numero_operacao,
            firstOperacao.origem,
            firstOperacao.vencimento_original.split('-')[0],
            firstOperacao.diasVenc,
            this.americaDate(firstOperacao.vencimento),
            firstOperacao.recebimento ? this.americaDate(firstOperacao.recebimento) : '',
            parcelaTotal,
            principalTotal,
            jurosTotal,
            multaTotal,
            descontoTotal,
            honorariosTotal,
            realizadoTotal,
            taxaCartaoTotal,
            comissaoTotal,
            repasseTotal,
            firstOperacao.forma,
          ]
        })

        worksheet2.addRows(worksheet2Data)
      } else {
        const newDadosOperacao = _.orderBy(dadosOperacao, ['cliente', 'aluno', 'numero_operacao', 'parcela'], ['asc'])
        newDadosOperacao.forEach(dados => {
          worksheet2.addRow([
            dados.instituicao,
            dados.cliente,
            dados.aluno,
            dados.documento ? this.cpfLabel(dados.documento) : '',
            dados.situacao,
            dados.numero_acordo,
            dados.numero_operacao,
            dados.origem,
            dados.vencimento_original.split('-')[0],
            dados.diasVenc,
            this.americaDate(dados.vencimento),
            dados.recebimento ? this.americaDate(dados.recebimento) : '',
            dados.parcela,
            parseFloat(dados.principal),
            parseFloat(dados.juros),
            parseFloat(dados.multa),
            parseFloat(dados.desconto),
            parseFloat(dados.honorarios),
            parseFloat(dados.realizado),
            parseFloat(dados.taxa_cartao),
            parseFloat(dados.comissao),
            parseFloat(dados.repasse),
            dados.forma,
          ])
        })
      }
      if (this.filtro.credor.id !== parseInt(process.env.VUE_APP_ROOT_EDUCA_MAIS)) {
        // worksheet2.spliceColumns(5, 5)
        worksheet2.spliceColumns(5, 1)
        worksheet3.spliceColumns(16, 1)
        worksheet.spliceColumns(4, 1)
      }
      const totalRowWk2 = worksheet2.addRow({
        principal: totais.wk2.principal,
        juros: totais.wk2.juros,
        multa: totais.wk2.multa,
        desconto: totais.wk2.desconto,
        honorarios: totais.wk2.honorarios,
        realizado: totais.wk2.realizado,
        taxa_cartao: totais.wk2.taxa_cartao,
        comissao: totais.wk2.comissao,
        repasse: totais.wk2.repasse,
      })
      totalRowWk2.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk2.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      const totalRowWk1 = worksheet.addRow({
        principal: totais.wk1.principal,
        juros: totais.wk1.juros,
        multa: totais.wk1.multa,
        desconto: totais.wk1.desconto,
        honorarios: totais.wk1.honorarios,
        realizado: totais.wk1.realizado,
        taxa_cartao: totais.wk1.taxa_cartao,
        comissao: totais.wk1.comissao,
        repasse: totais.wk1.repasse,
      })
      totalRowWk1.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk1.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      const totalRowWk3 = worksheet3.addRow({
        valor_acordo_total_principal: totais.wk3.valor_acordo_total_principal,
        valor_acordo_total_juros: totais.wk3.valor_acordo_total_juros,
        valor_acordo_total_multas: totais.wk3.valor_acordo_total_multas,
        valor_acordo_total_desconto: totais.wk3.valor_acordo_total_desconto,
        valor_acordo_total_honorarios: totais.wk3.valor_acordo_total_honorarios,
        valor_acordo_total_realizado: totais.wk3.valor_acordo_total_realizado,
        taxa_cartao: totais.wk3.taxa_cartao,
        comissao: totais.wk3.comissao,
      })
      totalRowWk3.font = {
        name: 'Calibri',
        family: 2,
        bold: true,
        size: 11,
      }
      totalRowWk3.eachCell({ includeEmpty: false }, cell => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'F4A460' },
        }
      })

      wk1Columns.map(key => {
        worksheet.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      wk2Columns.map(key => {
        worksheet2.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      wk3Columns.map(key => {
        worksheet3.getColumn(`${key}`).numFmt = '"R$"#,##0.00;[Red]-"R$"#,##0.00'
      })

      worksheet.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet2.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet3.eachRow({ includeEmpty: false }, (row, rowNumber) => {
        row.eachCell(cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'ffffff' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })
      worksheet2.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })
      worksheet3.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: false }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], `${credor.nome?.replaceAll(' ', '_')}-RECEBIMENTOS_${data_recebimento_final.replaceAll('/', '-')}.xlsx`, { type: 'application/octet-stream' })
      })

      const table = `
        <tr>
          <td width="144" style="width:108.2pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${totaisCPF.length}</span></p>
          </td>
          <td width="144" style="width:108.1pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.data.parcelas}</span></p>
          </td>
          <td width="134" style="width:100.15pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.principal), true)}</span></p>
          </td>
          <td width="105" style="width:78.65pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.juros), true)}</span></p>
          </td>
          <td width="104" style="width:77.65pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.multa), true)}</span></p>
          </td>
          <td width="120" style="width:89.65pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.desconto), true)}</span></p>
          </td>
          <td width="111" style="width:83.5pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.honorarios), true)}</span></p>
          </td>
          <td width="99" style="width:74.05pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.realizado), true)}</span></p>
          </td>
          <td width="83" style="width:62.1pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.txCartao), true)}</span></p>
          </td>
          <td width="83" style="width:62.1pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.comissao), true)}</span></p>
          </td>
          <td width="83" style="width:62.1pt; padding:7.5pt 7.5pt 7.5pt 7.5pt">
              <p class="MsoNormal" align="center" style="text-align:center"><span style="font-family: Montserrat, -apple-system, 'Segoe UI', sans-serif;">${this.valorBr(parseFloat(totais.wk1.repasse), true)}</span></p>
          </td>
      </tr>
      `

      return { blob, table }
    },
  },
}
</script>
